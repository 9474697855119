<template>
  <div class="table-responsive">
    <table class="table table-hover">
      <thead class="thead-light">
        <th>Reviewer</th>
        <th>Product</th>
        <th style="min-width: 400px;">Comment</th>
        <th>Rating</th>
        <th>Tag</th>
        <th>Reviewed at</th>
        <th>Status</th>
      </thead>
      <tbody>
        <tr v-if="model.length == 0">
          <td colspan="9" align="center">No data in this list</td>
        </tr>
        <tr v-else v-for="(m, i) in model" :key="i">
          <td>
            {{ m.reviewer_name }}
          </td>
          <td>
            {{ m.title }}
          </td>
          <td class="text-break">
            <p class="m-0" v-if="m.comment">{{m.read_more ? m.comment : m.comment.substr(0, 49)}} {{!m.read_more && m.comment.length > 50 ? "..." : ""}}</p>
            <span v-if="!m.read_more && m.comment && m.comment.length > 50" class="text-grey cursor-pointer" @click="readMore(i)">Read more</span>
            <div class="row no-gutters" v-if="m.review_images && m.review_images.filter(el => el).length > 0">
              <div class="col" v-for="(img, j) in m.review_images" :key="`active-img-${j}`">
                <b-img-lazy :src="img" class="cursor-pointer" thumbnail fluid @click.native="openGallery(m.review_images, j)" />
              </div>
              <div class="col" v-for="j in 5 - m.review_images.length" :key="`inactive-img-${j}`"></div>
            </div>
            <hr v-if="m.comment || m.review_images && m.review_images.filter(el => el).length" class="my-2" />
            <template v-if="m.review_feedbacks.length > 0">
              <div class="card p-2">
                <div class="d-flex justify-content-between">
                  <b>{{merchantData.merchant_full_name}}</b>
                  <p class="text-grey m-0">
                    {{formatDate(new Date(m.review_feedbacks[0].created_at), "DD-MM-YY HH:mm")}}
                    <fawesome-pro icon="trash" class="cursor-pointer text-black hover-danger ml-1" @click.native="deleteReply(i)"/>
                  </p>
                </div>
                <hr class="my-2" />
                <p class="m-0">{{m.review_feedbacks[0].comment}}</p>
              </div>
            </template>
            <button v-else-if="!m.reply" type="button" class="btn btn-wz-gold btn-sm" @click="showReply(i)">Leave a reply?</button>
            <template v-else-if="!m.reply.submitted">
              <form @submit.prevent="sendReply(i)">
                <textarea v-model="m.reply.text" placeholder="Enter a message" class="form-control" rows="4"></textarea>
                <div class="border bg-grey d-flex">
                  <button class="btn bg-white border ml-auto"><fawesome-pro icon="paper-plane" /></button>
                </div>
              </form>
            </template>
          </td>
          <td class="text-nowrap">
            <font-awesome icon="star" color="#d89700" v-for="j in m.rating" :key="`active-${j}`"/>
            <font-awesome icon="star" v-for="j in 5 - m.rating" :key="`inactive-${j}`"/>
          </td>
          <td>
            {{ m.tag ? m.tag.split(';').filter(el => el).join(', ') : '' }}
          </td>
          <td>
            {{ formatDate(new Date(m.created_at), "D MMMM YYYY HH:mm") }}
          </td>
          <td>
            {{ translateStatus(m.approved_status) }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import { toaster } from "@/_helpers";
import moment from "moment";

export default {
  name: "ProductListTable",
  props: ["items"],
  data() {
    return {
      model: this.items,
    }
  },
  computed: {
    merchantData() {
      return this.$store.getters.getMerchantData;
    },
  },
  methods: {
    openGallery(images, index) {
      this.$bvModal.show("modal-img-gallery")
      this.$emit("openGallery", {images, index})
    },
    readMore(index) {
      this.model[index].read_more = true
      this.model.splice(index, 1, this.model[index])
    },
    showReply(index) {
      this.model[index].reply = {
        text: "Terima kasih telah berbelanja di official store kami di Whizliz. Kami tunggu pesananmu yang selanjutnya yaa~",
        submitted: null,
      }
      this.model.splice(index, 1, this.model[index])
    },
    async sendReply(index) {
      this.model[index].reply.submitted = this.formatDate(new Date(), "DD-MM-YYYY HH:mm")
      const reply = {
        parent_id: this.model[index].id,
        comment: this.model[index].reply.text
      }
      try {
        const res = await this.$api.product.saveMerchantReply(reply)
        if (res.status === 200) {
          toaster.make(res.data.message, "success");
          this.$emit("changed")
        } else toaster.make(res.data.message, "danger")
      } catch (e) {
        console.error(e);
      }
    },
    async deleteReply(index) {
      try {
        const res = await this.$api.product.deleteMerchantReply(this.model[index].review_feedbacks[0].id)
        if (res.status === 200) {
          toaster.make(res.data.message, "success");
          this.$emit("changed")
        } else toaster.make(res.data.message, "danger")
      } catch (e) {
        console.error(e);
      }
    },
    translateStatus(status) {
      switch (status) {
        case "P": {
          return "Pending"
        }
        case "A": {
          return "Approved"
        }
        case "R": {
          return "Rejected"
        }
        default: {
          return status
        }
      }
    },
    formatDate(value, format = "YYYY-MM-DD HH:mm:ss") {
      return value ? moment(String(value)).format(format).toString() : value
    },
    formatPrice(value, decimal = 0) {
      return value ? value.toLocaleString('id', {minimumFractionDigits: decimal}) : value
    },
  },
  watch: {
    items (newVal) {
      this.model = newVal.map(el => {
        el.read_more = false,
        el.reply = null
        return el
      })
    }
  }
}
</script>
