<template>
  <div class="container-fluid">
    <MobileGuide />
    <ProductListNavTab />
    <div class="card p-2">
      <div class="row p-1 align-items-center">
        <div class="col-md-auto">
          <h2 class="roboto subtitle bold">Manage Products</h2>
        </div>
        <div class="col d-lg-flex">
          <div class="d-flex ml-auto">
            <button type="button" class="btn btn-wz-export ml-auto text-nowrap overflow-hidden" @click="exportCsv()">Export</button>
            <div class="d-flex align-items-center text-nowrap">
              <input ref="start" type="date" class="form-control w-auto">
              <div class="p-2">to</div>
              <input ref="end" type="date" class="form-control w-auto">
            </div>
          </div>
          <form
            @submit.prevent="filterReviews"
            class="input-search flex-grow-1 flex-lg-grow-0"
          >
            <input
              ref="search"
              type="text"
              placeholder="Search Product Name"
              class="form-control"
            />
            <button class="btn ml-auto"><font-awesome icon="search" /></button>
          </form>
        </div>
      </div>
      <b-tabs fill>
        <b-tab title="All" class="pt-5" @click="currentTab = 'all'">
          <ProductReviewTable :items="listAll.reviews" @openGallery="gallery = $event" @changed="refreshList(listAll.page)" />
          <b-pagination
            class="float-right"
            v-model="listAll.page"
            :total-rows="listAll.total"
            :per-page="listAll.limit"
            @change="getReviews('all', listAll.limit, $event, search)"
          />
          <select
            v-model="listAll.limit"
            class="form-control w-auto float-right"
            @input="getReviews('all', $event.target.value, 1, search)"
          >
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="30">30</option>
            <option value="40">40</option>
            <option value="50">50</option>
          </select>
        </b-tab>
        <b-tab title="Pending" class="pt-5" @click="currentTab = 'pending'">
          <ProductReviewTable :items="listPending.reviews" @openGallery="gallery = $event" @changed="refreshList(listPending.page)" />
          <b-pagination
            class="float-right"
            v-model="listPending.page"
            :total-rows="listPending.total"
            :per-page="listPending.limit"
            @change="getReviews('pending', listPending.limit, $event, search)"
          />
          <select
            v-model="listPending.limit"
            class="form-control w-auto float-right"
            @input="getReviews('pending', $event.target.value, 1, search)"
          >
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="30">30</option>
            <option value="40">40</option>
            <option value="50">50</option>
          </select>
        </b-tab>
        <b-tab title="Approved" class="pt-5" @click="currentTab = 'approved'">
          <ProductReviewTable :items="listApproved.reviews" @openGallery="gallery = $event" @changed="refreshList(listApproved.page)" />
          <b-pagination
            class="float-right"
            v-model="listApproved.page"
            :total-rows="listApproved.total"
            :per-page="listApproved.limit"
            @change="getReviews('approved', listApproved.limit, $event, search)"
          />
          <select
            v-model="listApproved.limit"
            class="form-control w-auto float-right"
            @input="getReviews('approved', $event.target.value, 1, search)"
          >
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="30">30</option>
            <option value="40">40</option>
            <option value="50">50</option>
          </select>
        </b-tab>
        <b-tab title="Rejected" class="pt-5" @click="currentTab = 'rejected'">
          <ProductReviewTable :items="listRejected.reviews" @openGallery="gallery = $event" @changed="refreshList(listRejected.page)" />
          <b-pagination
            class="float-right"
            v-model="listRejected.page"
            :total-rows="listRejected.total"
            :per-page="listRejected.limit"
            @change="getReviews('rejected', listRejected.limit, $event, search)"
          />
          <select
            v-model="listRejected.limit"
            class="form-control w-auto float-right"
            @input="getReviews('rejected', $event.target.value, 1, search)"
          >
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="30">30</option>
            <option value="40">40</option>
            <option value="50">50</option>
          </select>
        </b-tab>
      </b-tabs>
    </div>
    <b-modal
      id="modal-img-gallery"
      size="xl"
      hide-header
      hide-footer
      centered
    >
      <div class="row no-gutters" v-if="gallery">
        <div class="col-md-10 mb-2">
          <img :src="gallery.images[gallery.index]" class="w-100 rounded"/>
        </div>
        <div class="col-md-2">
          <div class="row no-gutters justify-content-center d-lg-block pl-lg-1">
            <div class="col" v-for="(img, i) in gallery.images" :key="i">
              <img @click="gallery.index = i" :src="img" class="w-100 rounded border cursor-pointer" :class="{'border-warning' : i == gallery.index}" />
            </div>
            <div class="col" v-for="i in 5 - gallery.images.length" :key="'inactive-' + i"></div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { toaster } from "@/_helpers";
import MobileGuide from "@/components/navs/MobileGuide";
import ProductListNavTab from "@/components/product/ProductListNavTab";
import ProductReviewTable from "@/components/product/ProductReviewTable";

const XLSX = require("xlsx");

export default {
  name: "ProductReview",
  components: {
    MobileGuide,
    ProductListNavTab,
    ProductReviewTable,
  },
  data() {
    return {
      gallery: null,
      currentTab: "all",
      search: null,
      start: null,
      end: null,
      listAll: {
        page: 1,
        limit: 5,
        reviews: [],
        total: 0,
      },
      listPending: {
        page: 1,
        limit: 5,
        reviews: [],
        total: 0,
      },
      listApproved: {
        page: 1,
        limit: 5,
        reviews: [],
        total: 0,
      },
      listRejected: {
        page: 1,
        limit: 5,
        reviews: [],
        total: 0,
      }
    }
  },
  mounted() {
    this.refreshList()
  },
  methods: {
    refreshList(page = 1) {
      this.getReviews("all", this.listAll.limit, page, this.search, this.start, this.end)
      this.getReviews("pending", this.listPending.limit, page, this.search, this.start, this.end)
      this.getReviews("approved", this.listApproved.limit, page, this.search, this.start, this.end)
      this.getReviews("rejected", this.listRejected.limit, page, this.search, this.start, this.end)
    },
    filterReviews() {
      this.start = this.$refs.start.value
      this.end = this.$refs.end.value
      this.search = this.$refs.search.value
      this.refreshList()
    },
    async getReviews(status, limit, offset, title, start, end) {
      try {
        const res = await this.$api.product.getProductReview(status, limit, offset - 1, title, start, end)
        if (res.status === 200) {
          const d = res.data
          switch (status) {
            case "all": {
              this.listAll.total = d.meta.total
              this.listAll.reviews = d.data
              this.listAll.page = offset
              break;
            }
            case "pending": {
              this.listPending.total = d.meta.total
              this.listPending.reviews = d.data
              this.listPending.page = offset
              break;
            }
            case "approved": {
              this.listApproved.total = d.meta.total
              this.listApproved.reviews = d.data
              this.listApproved.page = offset
              break;
            }
            case "rejected": {
              this.listRejected.total = d.meta.total
              this.listRejected.reviews = d.data
              this.listRejected.page = offset
              break;
            }
          }
        } else toaster.make(res.data.message, "danger")
      } catch (e) {
        console.error(e); 
      }
    },
    async exportCsv() {
      try {
        const res = await this.$api.product.getProductReview(this.currentTab, 1000, 0, this.search, this.start, this.end)
        if (res.status === 200) {
          const arrData = res.data.data.map(el => ({
              reviewer: el.reviewer_name,
              product: el.title,
              comment: el.comment,
              rating: el.rating,
              tag: el.tag,
              reviewed_at: el.created_at,
              status: el.approved_status,
            }))
          // https://lifesaver.codes/answer/how-to-simply-export-a-worksheet-to-xlsx
          /* make the worksheet */
          const ws = XLSX.utils.json_to_sheet(arrData);
          /* add to workbook */
          const wb = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
          /* generate an XLSX file */
          XLSX.writeFile(wb, "product_review.xlsx");
        }
      } catch (e) {
        console.error(e);
      }
    }
  }
}
</script>
